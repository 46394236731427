/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

/* =================================
 l_header
================================= */
.l_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  background: rgba(0, 0, 0, .8);
}
.nav_wrap {
  @include sp {
    display: none;
  }
  @include pc_tab {
    display: flex;
    align-items: center;
  }
  .logo_wrap {
    padding: 20px 28px;
    @media screen and (max-width: 1360px) {
      padding: 20px 0 20px 28px;
    }
  }
  .list {
    display: flex;
    margin: 0 0 0 22px;
    padding: 20px 28px 20px 0;
    @media screen and (max-width: 1360px) {
      padding: 20px 0 20px;
    }
    > .item {
      position: relative;

      > .link {
        position: relative;
        display: block;
        padding: 0 0 0 14px;
        color: $white;
        &::before {
          position: absolute;
          top: calc(50% - 1px);
          left: 0;
          content: url(#{$img_dir}/common/icon_arrow_3.svg);
          transform: translateY(-50%);
        }
        &::after {
          position: absolute;
          bottom: 0;
          left: calc(50% + 7px);
          display: block;
          width: 30px;
          height: 1px;
          border-bottom: 1px solid $color_1;
          content: "";
          opacity: 0;
          transition: opacity .3s, bottom .3s;
          transform: translateX(-50%);
        }
        &:hover {
          &::after {
            bottom: -4px;
            opacity: 1;
          }
        }
      }
      & + .item {
        margin: 0 0 0 15px;
      }
    }
    ._menu {
      position: relative;
      padding: 0 14px 0 0;
      cursor: pointer;
      &::after {
        position: absolute;
        top: calc(50% - 2px);
        right: 0;
        content: url(#{$img_dir}/common/icon_arrow_4.svg);
        transform: translateY(-50%);
      }
    }
  }
  .child_list {
    position: absolute;
    top: 55px;
    left: calc(50% + 7px);
    display: none;
    padding: 15px 30px;
    width: 252px;
    background: $black;
    transform: translateX(-50%);
    &::before {
      position: absolute;
      top: -13px;
      left: 50%;
      border-right: 7px solid transparent;
      border-bottom: 13px solid $black;
      border-left: 7px solid transparent;
      content: "";
      transform: translateX(-50%);
    }
    .item {
      & + .item {
        border-top: 1px dotted rgba(255, 255, 255, .2);
      }
    }
    .link {
      position: relative;
      display: block;
      padding: 15px 0 15px 14px;
      color: $white;
      line-height: 1;
      &::before {
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        content: url(#{$img_dir}/common/icon_arrow_5.svg);
        transform: translateY(-50%);
      }
    }
  }
  .btn_wrap {
    display: flex;
    align-items: center;
    margin-left: auto;

    .tel_wrap {
      display: block;
      margin: 0 29px 0 0;
    }
    .btn {
      display: block;
      padding: 16px 0;
      width: 80px;
      height: 80px;
      color: $white;
      transition: background-color .3s;
      &._1 {
        border: 1px solid $color_2;
        background: $color_2;
      }
      &._2 {
        border: 1px solid $color_1;
        background: $color_1;
      }
      &:hover {
        background: $black;
      }
    }
  }
}
.sp_btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 100;
  width: 50px;
  height: 50px;
  border: 1px solid $color_1;
  .bar {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 24px;
    height: 2px;
    background: $color_1;
    transition: top .3s, transform .3s;
    transform: translateX(-50%);

    &._1 {
      top: 16px;
    }
    &._2 {
      top: 24px;
      z-index: 2;
    }
    &._3 {
      top: 32px;
    }
  }
  &._open {
    .bar {
      &._1 {
        top: 24px;
        transform: translateX(-50%) rotate(45deg);
      }
      &._2 {
        width: 0;
      }
      &._3 {
        top: 24px;
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }
}
.sp_logo_wrap {
  position: fixed;
  z-index: 99;
  padding: 12px 20px;
  width: 100%;
  height: 66px;
  background: rgba(0, 0, 0, .8);
  .logo {
    width: auto;
    height: 40px;
  }
}
.sp_head {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  overflow-y: auto;
  padding: 66px 0 0;
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  background: rgba(0, 0, 0, .9);
  transition: left .3s;
  .c_langbar .lang_list {
    padding: 0 20px;
  }
  .sp_nav {
    padding: 0 20px;
  }
  .list2 {
    margin: 30px 0 15px;
    ._menu + .item {
      margin: 20px auto 0;
    }
    ._menu + ._menu {
      margin: 30px auto 0;
    }
    .link_heading {
      position: relative;
      display: block;
      padding: 10px 0;
      border-bottom: 1px solid rgba(255, 255, 255, .15);
    }
    .child_list {
      padding: 0 0 0 10px;
    }
    .link {
      position: relative;
      display: block;
      padding: 20px 0 20px 14px;
      border-bottom: 1px dotted rgba(255, 255, 255, .2);
      color: $white;
      &::before {
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        content: url(#{$img_dir}/common/icon_arrow_5.svg);
        transform: translateY(-50%);
      }
    }
  }
  .c_cta {
    position: relative;
    z-index: 0;
    margin: 40px auto 0;
    padding: 0 20px;
    background: $black;
    .contact_wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 20px 40px;
      width: 100%;
      background: rgba($color: #000, $alpha: .5);
      .tel_wrap {
        position: relative;
        margin: 0 auto 46px;
        width: 100%;
        &::after {
          position: absolute;
          bottom: -27px;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          border-bottom: 1px solid $color_4;
          content: "";
        }
      }
      .icon_tel {
        margin: 0 8px 6px 0;
      }
      .tel_btn {
        position: relative;
        display: block;
        margin: 19px 0 0;
        width: 100%;
        height: 70px;
        border-radius: 35px 35px 35px 35px;
        background: $color_1;
        color: $white;
        &::before {
          position: absolute;
          top: 5px;
          left: 5px;
          display: block;
          width: 100%;
          height: 70px;
          border: 1px solid $color_1;
          border-radius: 35px 35px 35px 35px;
          content: "";
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 15px;
          z-index: 3;
          content: url(#{$img_dir}/common/icon_arrow_1.svg);
          transform: translateY(-50%);
        }
        .tel {
          line-height: 70px;
        }
      }
    }
    .block_heading {
      width: 100%;
      .logo {
        width: 122px;
        height: auto;
      }
    }
    .btns {
      width: 100%;
      .c_btn_3 {
        display: block;
        margin: 25px 0 0;
        width: 100%;
      }
      .c_btn_3 + .c_btn_3 {
        margin: 25px 0 0;
      }
    }
  }
  &._open {
    left: 0;
  }
}

/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  position: relative;
  z-index: 1;
  width: 100%;

  @include sp {
    padding: 38px 0 60px;
  }
  @include pc_tab {
    padding: 30px 0 60px;
  }
  .breadcrumbs {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 20px;
    max-width: 1240px;
    white-space: nowrap;

    @include sp {
      margin: 0 auto -5px;
    }
    @include pc_tab {
      margin: 0 auto;
    }
    & > span {
      @include sp {
        padding: 0 0 5px;
      }
    }
  }
  a {
    color: $white;
  }
}
.arrow_breadcrumbs {
  position: relative;
  margin: 0 17px 0 12px;
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 5px;
    content: url(#{$img_dir}/common/icon_arrow_5.svg);
    transform: translateY(-50%);

    @include sp {
      top: calc(50% - 4px);
    }
  }
}

/* =================================
 l_main_visual
================================= */
.l_main_visual {
  position: relative;
  z-index: 2;
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: block;
    width: 100%;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
    );
    content: "";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);

    @include sp {
      height: 160px;
    }
    @include pc_tab {
      height: 114px;
    }
  }
}

/* =================================
 l_content
================================= */
.l_content {
  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    background-position: top left;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    content: "";

    @include sp {
      bottom: -119px;
      height: 119px;
      background-image: url(#{$img_dir}/p_top/sp/sec8_bg.png);
    }
    @include pc_tab {
      bottom: -290px;
      height: 339px;
      background-image: url(#{$img_dir}/p_top/sec8_bg.png);
    }
  }
}

/* =================================
 l_main
================================= */

/* =================================
 l_side
================================= */
.l_side {
  @include sp {
    margin: 60px auto 0;
  }
  @include pc_tab {
    margin: 0 0 0 60px;
    width: 256px;
  }
  .ttl {
    & + .c_list_1 {
      margin: 20px 0 0;
      padding: 20px 0 0;
      border-top: 1px solid rgba(255, 255, 255, .3);
    }
  }
  .newposts_wrap {
    .c_list_1 {
      .item {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        max-height: 3em;
        -webkit-line-clamp: 1;
        line-height: 1.5em;
        .link {
          display: block;
          color: $white;
        }
        & + .item {
          @include sp {
            margin: 20px 0 0;
            padding: 20px 0 0 14px;
            border-top: 1px solid rgba(255, 255, 255, .16);
            &::before {
              top: 17px;
            }
          }
        }
      }
    }
  }
  .category_wrap {
    margin: 40px 0 0;
    .c_list_1 {
      .item {
        .link {
          display: block;
          color: $white;
        }
      }
    }
  }
  .archive_wrap {
    margin: 40px 0 0;
    .ttl {
      margin: 0 0 20px;
      padding: 0 0 20px;
      border-bottom: 1px solid $color_4;
    }
    .select {
      position: relative;
      padding: 9px 20px;
      width: 100%;
      border: 1px solid $color_9;
      border-radius: 5px;
      background: $color_10;
      option {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

/* =================================
 l_footer
================================= */
.l_footer_wrap {
  position: relative;
  z-index: 0;
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    content: "";

    @include sp {
      background-image: url(#{$img_dir}/p_top/sp/sec1_bg.jpg);
    }
    @include pc_tab {
      background-image: url(#{$img_dir}/p_top/sec1_bg.jpg);
    }
  }
}
.l_footer {
  position: relative;
  background: rgba(0, 0, 0, .5);

  @include sp {
    padding: 40px 20px 70px;
  }
  .footer_nav {
    margin: 0 auto;
    max-width: 1200px;

    @include pc_tab {
      display: flex;
      margin: 0 auto 20px;
      padding: 80px 0 0;
    }
    .link_heading {
      position: relative;
      display: block;
      padding: 0 0 10px;
      border-bottom: 1px solid rgba(255, 255, 255, .15);
    }
    .list {
      @include sp {
        & + .list {
          margin: 40px 0 0;
        }
      }
      @include pc_tab {
        width: 200px;
        & + .list {
          margin: 0 0 0 40px;
        }
      }
    }
    .list {
      .child_list {
        margin-left: 10px;
      }
      .link {
        position: relative;
        display: block;
        border-bottom: 1px dotted rgba(255, 255, 255, .2);
        color: $white;
        color: $white;

        @include sp {
          padding: 20px 0 20px 14px;
        }
        @include pc_tab {
          padding: 15px 0 15px 14px;
        }
        &::before {
          position: absolute;
          top: calc(50% - 2px);
          left: 0;
          content: url(#{$img_dir}/common/icon_arrow_5.svg);
          transform: translateY(-50%);
        }
        &._first {
          @include sp {
            padding: 0 0 20px 14px;
          }
          @include pc_tab {
            padding: 0 0 15px 14px;
          }
          &::before {
            top: calc(50% - 10px);
          }
        }
      }
    }
    .right_box {
      @include sp {
        margin: 40px 0 0;
      }
      @include pc_tab {
        margin-left: auto;
      }
    }
    .bnr_list {
      display: flex;
      .item {
        @include sp {
          width: 160px;
        }
        @include pc_tab {
          width: 219px;
        }
        & + .item {
          @include sp {
            margin: 0 0 0 15px;
          }
          @include pc_tab {
            margin: 0 0 0 20px;
          }
        }
        .link {
          display: block;
        }
        .bnr {
          max-width: 100%;
        }
      }
    }
    .sns_box {
      display: flex;

      @include sp {
        justify-content: center;
        margin: 30px 0;
      }
      @include pc_tab {
        justify-content: flex-end;
        margin: 40px 0 50px;
      }
      .item {
        & + .item {
          margin: 0 0 0 16px;
        }
        .link {
          display: block;
          width: 48px;
          height: 48px;
          .icon {
            width: 100%;
          }
        }
      }
    }
    .c_langbar {
      @include pc_tab {
        display: inline-block;
      }
    }
  }
  .footer_subnav {
    margin: 0 auto;
    max-width: 1200px;

    @include pc_tab {
      display: flex;
      padding: 0 0 80px;
    }
    .list {
      @include sp {
        display: none;
      }
      @include pc_tab {
        display: flex;
        margin-left: auto;
      }
      .link {
        position: relative;
        display: block;
        margin-left: 20px;
        padding: 0 0 0 14px;
        color: $white;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          content: url(#{$img_dir}/common/icon_arrow_5.svg);
          transform: translateY(-50%);
        }
      }
    }
  }
}

.l_footer_deco_1 {
  position: absolute;
  z-index: 0;
  background-position: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  mix-blend-mode: color-dodge;
  @include sp {
    top: -119px;
    left: 0;
    width: 100vw;
    height: 100.3vw;
    background-image: url(#{$img_dir}/common/sp/deco_top.png);
  }
  @include pc_tab {
    top: -108px;
    left: 0;
    width: 925px;
    height: 940px;
    background-image: url(#{$img_dir}/common/deco_top.png);
  }
}
.l_footer_deco_2 {
  position: absolute;
  z-index: 0;
  background-position: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  mix-blend-mode: color-dodge;
  @include sp {
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 82.7vw;
    background-image: url(#{$img_dir}/common/sp/deco_bottom.png);
  }
  @include pc_tab {
    right: 0;
    bottom: 0;
    width: 707px;
    height: 425px;
    background-image: url(#{$img_dir}/common/deco_bottom.png);
  }
}
