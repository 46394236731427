/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl_1 {
  display: flex;
  vertical-align: middle;

  writing-mode: vertical-rl;
  .c_ttl_1_inner {
    display: block;
    margin: 0 auto;
  }
  .en {
    display: block;
    font-family: $font_2;

    writing-mode: horizontal-tb;
    @include sp {
      margin: 23px auto 0;
    }
    @include pc_tab {
      margin: 25px auto 0;
    }
  }
}
.c_ttl_box {
  .c_ttl_1 {
    display: flex;
    margin: 0 auto;
  }
}
.c_ttl_1_caption {
  @include sp {
    margin: 50px 0 0;
  }
  @include pc_tab {
    margin: 43px 0 0;
  }
}

// c_ttl_2
.c_ttl_2 {
  display: flex;
  vertical-align: middle;

  writing-mode: vertical-rl;
  .c_ttl_2_inner {
    display: block;
    margin: 0 auto;
  }
  .note {
    margin: -11px 0;
  }
  .br {
    display: block;
    width: 20px;
  }
}
.c_ttl_2_box {
  position: relative;
  z-index: 2;
  .c_ttl_2 {
    margin: 0 auto;
  }
  .en {
    position: relative;
    display: block;
    letter-spacing: 1px;
    font-family: $font_2;

    writing-mode: horizontal-tb;
    @include sp {
      margin: 0 auto 50px;
    }
    @include pc_tab {
      margin: 0 auto 50px;
    }
    &::after {
      position: absolute;
      bottom: -20px;
      left: 50%;
      display: block;
      width: 37px;
      height: 1px;
      border-bottom: 1px solid rgba(255, 255, 255, .8);
      content: "";
      transform: translateX(-50%);
    }
  }
}
.c_ttl_2_caption {
  @include sp {
    margin: 50px 0 0;
  }
  @include pc_tab {
    margin: 43px 0 0;
  }
}

// c_ttl_3
.c_ttl_3 {
  position: relative;
  padding: 50px 0 70px;
  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    content: url(#{$img_dir}/common/c_ttl_3_deco.svg);
    transform: translateX(-50%);
  }
  &::after {
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 95px;
    height: 1px;
    border-top: 1px solid $white;
    content: "";
    opacity: .3;
    transform: translateX(-50%);
  }
}

// c_ttl_4
.c_ttl_4 {
  padding: 5px;
}

// c_ttl_5
.c_ttl_5 {
  @include sp {
    margin: 40px 0 30px;
  }
  @include pc_tab {
    margin: 60px 0 40px;
  }
}

// c_ttl_6
.c_ttl_6 {
  position: relative;
  padding: 0 0 0 24px;
  &::before {
    position: absolute;
    top: 2px;
    left: 0;
    content: url(#{$img_dir}/common/c_ttl_6_deco.svg);
  }
}

/* =================================
   buttons
  ================================= */
// c_gotop

// c_btn_1
.c_btn_1 {
  position: relative;
  display: inline-block;
  border: 1px solid $color_1;
  background-color: $black;
  color: $white !important;

  @include sp {
    margin: 0 auto;
    padding: 10px 30px;
    min-height: 60px;
    width: calc(100vw - 70px);
  }
  @include pc_tab {
    padding: 10px 55px;
    min-height: 50px;
    transition: background-color .3s;
  }
  &_wrap {
    @include sp {
      margin: 50px auto 0;
    }
    @include pc_tab {
      margin: 40px auto 0;
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 19px;
    content: url(#{$img_dir}/common/icon_arrow_1.svg);
    transform: translateY(-50%);
  }
  .btn_inner {
    display: block;

    @include sp {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover {
    @include pc_tab {
      background-color: $color_1;
    }
  }
}

// c_btn_2
.c_btn_2 {
  position: relative;
  display: inline-block;
  border: 1px solid $color_1;
  background-color: $black;
  color: $white !important;
  letter-spacing: 0;

  @include sp {
    margin: 0 auto;
    padding: 10px 30px;
    min-height: 60px;
    width: calc(100vw - 70px);
  }
  @include pc_tab {
    padding: 10px 55px;
    min-height: 50px;
    transition: background-color .3s;
  }
  &_wrap {
    letter-spacing: -1rem;

    @include sp {
      margin: 50px auto 0;
    }
    @include pc_tab {
      margin: 40px auto 0;
    }
    .c_btn_2 + .c_btn_2 {
      @include sp {
        margin: 20px 0 0;
      }
      @include pc_tab {
        margin: 0 0 0 40px;
      }
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 19px;
    z-index: 3;
    content: url(#{$img_dir}/common/icon_arrow_1.svg);
    transform: translateY(-50%);
  }
  &._pdf {
    &::after {
      top: calc(50% + 1px);
      content: url(#{$img_dir}/common/icon_pdf.svg);
    }
  }
  &._pdf2 {
    &::after {
      top: calc(50% + 1px);
      content: url(#{$img_dir}/common/icon_pdf_2.svg);
    }
  }
  &._ex {
    &::after {
      top: calc(50% + 1px);
      content: url(#{$img_dir}/common/icon_external_2.svg);
    }
  }
  &._print {
    &::after {
      top: calc(50% + 1px);
      content: url(#{$img_dir}/common/icon_print.svg);
    }
  }
  .btn_inner {
    position: relative;
    z-index: 3;
    display: block;
    min-width: 211px;

    @include sp {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover {
    @include pc_tab {
      background-color: $color_1;
    }
  }
}

// c_btn_3
.c_btn_3 {
  position: relative;
  display: inline-block;
  min-height: 70px;
  color: $white !important;
  letter-spacing: 0;

  @include sp {
    margin: 0 auto;
    padding: 15px 30px;
    width: 100px;
  }
  @include pc_tab {
    padding: 15px 55px;
    min-width: 300px;
    transition: background-color .3s;
  }
  &_wrap {
    letter-spacing: -1rem;

    @include sp {
      margin: 50px auto 0;
    }
    @include pc_tab {
      margin: 40px auto 0;
    }
    .c_btn_2 + .c_btn_2 {
      @include sp {
        margin: 20px 0 0;
      }
      @include pc_tab {
        margin: 0 0 0 40px;
      }
    }
  }
  &::before {
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: 2;
    display: block;
    width: 0;
    height: calc(100% + 2px);
    background: $black;
    content: "";
    opacity: 0;
    transition: opacity .3s, width .3s;
  }
  .btn_inner {
    position: absolute;
    top: -6px;
    left: -5px;
    z-index: 3;
    display: block;
    width: 100%;
    height: 100%;
    transition: top .3s, left .3s, background-color .3s;
    .txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 19px;
      z-index: 3;
      content: url(#{$img_dir}/common/icon_arrow_1.svg);
      transform: translateY(-50%);
    }
  }
  &._1 {
    border: 1px solid $color_2;

    .btn_inner {
      background-color: $color_2;
    }
  }
  &._2 {
    border: 1px solid $color_1;

    .btn_inner {
      background-color: $color_1;
    }
  }
  &:hover {
    @include pc_tab {
      .btn_inner {
        top: 0;
        left: 0;
        background-color: transparent;
      }
    }
  }
}

// c_btn_4
.c_btn_4 {
  position: relative;
  display: inline-block;
  min-height: 72px;
  max-width: 320px;
  width: 100%;
  border: 1px solid $color_1;
  background-color: $black;
  color: $white !important;
  letter-spacing: 0;

  @include sp {
    margin: 0 auto;
    width: calc(100vw - 70px);
  }
  @include pc_tab {
    transition: background-color .3s;
  }
  &_wrap {
    display: flex;
    justify-content: center;
    letter-spacing: -1rem;

    .c_btn_4 + .c_btn_4 {
      @include sp {
        margin: 0 0 0 -1px;
      }
      @include pc_tab {
        margin: 0 0 0 40px;
      }
    }
  }
  &::after {
    position: absolute;
    top: 50%;
    right: 19px;
    z-index: 3;
    content: url(#{$img_dir}/common/icon_arrow_7.svg);
    transform: translateY(-50%);
  }
  .btn_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    display: block;
    width: 100%;
    transform: translate(-50%, -50%);

    @include sp {
      padding: 0 0 0 15px;
    }
  }
  &:hover {
    @include pc_tab {
      background-color: $color_1;
    }
  }
}

/* =================================
   icon
  ================================= */

/* =================================
   table
  ================================= */
// c_table_1
.c_table_1 {
  width: 100%;
  border-top: 1px solid $color_4;

  .row {
    border-bottom: 1px solid $color_4;
  }
  .ttl,
  .detail {
    padding: 20px 0;

    @include sp {
      display: block;
    }
  }
  .ttl {
    margin: 0 20px 0 0;

    @include sp {
      padding: 20px 0 0;
    }
  }
}

// c_table_2
.c_table_2 {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  .colttl {
    &._half {
      width: calc((50% - 90px) / 2);
    }
  }
  .min {
    width: 180px;
    background-color: #1c1c1c;
  }
  .rowttl {
    background-color: #1c1c1c;
  }
  .colttl {
    background-color: $color_8;
  }

  .colttl,
  .rowttl,
  .detail {
    padding: 20px;
    border: 1px solid $color_18;
  }
  .detail {
    .note_item {
      margin-top: 20px;
      .note_read {
        display: flex;
        &:not(:first-of-type) {
          margin-top: 15px;
        }
        &:before {
          content: "※";
        }
      }
    }

    .detail_nest_tbl {
      &._mt {
        margin-top: 20px;
      }
    }
  }

  .cource_price {
    color: $color_11;
    .price {
      font-size: 2rem;

      @include sp {
        font-size: 1.6rem;
      }
    }
  }
}

// c_table_3
.c_table_3 {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  .colttl,
  .rowttl,
  .detail {
    border: 1px solid $color_18;
  }
  .colttl {
    padding: 20px;
    background: $color_8;
  }
  .rowttl,
  .detail {
    padding: 15px;
  }
  .rowttl {
    width: 130px;
    background-color: #1c1c1c;
    &_large {
      width: calc(100% - 130px);
    }
  }

  .detail {
    .note_item {
      margin-top: 20px;
      .note_read {
        display: flex;
        &:not(:first-of-type) {
          margin-top: 15px;
        }
        &:before {
          content: "※";
        }
      }
    }
    .detail_nest_tbl {
      &._mt {
        margin-top: 20px;
      }
    }
  }
  .cource_price {
    color: $color_11;
    .price {
      font-size: 1.8rem;
    }
  }
}

/* =================================
   list
  ================================= */
// c_list_1
.c_list_1 {
  padding: 0;
  .item {
    position: relative;
    padding: 0 0 0 14px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: url(#{$img_dir}/common/icon_list_1.svg);
    }
    & + .item {
      margin-top: 5px;
    }
  }
}

// c_list_2
.c_list_2 {
  padding: 0;
  .item {
    position: relative;
    padding: 0 0 0 18px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      color: $color_1;
      content: "◆";
      font-size: 14px;
    }
  }
}

// c_list_3
.c_list_3 {
  padding: 0;
  list-style-type: none;
  counter-reset: number 0;
  .item {
    position: relative;
    padding: 0 0 20px 27px;
    border-bottom: 1px solid $color_4;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      color: $color_1;
      content: counter(number, decimal-leading-zero) ".";
      counter-increment: number;
      letter-spacing: 1px;
      font-size: 18px;
      font-family: $font_2;
    }
    & + .item {
      margin: 20px 0 0;
    }
  }
}

// c_sheet_menu
.c_sheet_menu {
  width: 100%;
  background: $black;

  @include sp {
    margin: 0 auto 30px;
  }
  @include pc_tab {
    display: flex;
  }
  .item {
    position: relative;

    @include sp {
      margin: 0 10px 0 0;
      width: 78.7vw;
      height: 112vw;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 2.7vw;
        background: $black;
        content: "";
        transition: height .3s;
      }
      &:after {
        top: auto;
        bottom: 0;
      }
      &.slick-center {
        height: 112vw;
        &:before,
        &:after {
          height: 0;
        }
      }
    }
    @include pc_tab {
      min-height: 500px;
      width: calc(100% / 3);
      height: 36.6vw;
    }
  }
  .link {
    position: relative;
    display: block;
    color: $white;

    @include sp {
      width: 100%;
      height: 112vw;
    }
    @include pc_tab {
      min-height: 500px;
      height: 36.6vw;

      &:hover {
        .photo {
          top: 50%;
          left: 50%;
          opacity: .75;
          transition: opacity .3s, transform .3s;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
  }
  .img_box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    @include sp {
      height: 112vw;
    }
    &::before {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      content: "";
      letter-spacing: 0;

      @include sp {
        height: 22.9vw;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
        );
        background: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
        );
        background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
        );
        opacity: .8;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
      }
      @include pc_tab {
        height: 6.9vw;
        background-image: url(#{$img_dir}/common/gradation_2.png);
        background-repeat: repeat-x;
      }
    }
    .photo {
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: cover;
      opacity: .42;
      transition: opacity .3s, transform .3s;
      transform: translate(-50%, -50%);

      @include sp {
        width: 100%;
      }
      @include pc_tab {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  .txt_box {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    height: 100%;
    transform: translateX(-50%);

    @include sp {
      padding: 8vw 0;
      @media screen and (max-width: 374px) {
        padding: 5vw 0;
      }
    }
    @include pc_tab {
      padding: 30px 0 40px;
    }
    .ttl {
      @include sp {
        position: absolute;
        top: 22.9vw;
        left: 50%;
        height: calc(96vw - 60px);
        transform: translateX(-50%);
      }
      @include pc_tab {
        min-height: 350px;
        height: calc(36.6vw - 147px);
      }
      .note {
        margin: -11px 0;
      }
    }
    .br {
      display: block;
      width: 20px;
    }
    .en {
      position: relative;
      display: block;
      width: 100%;

      @include sp {
        margin: 0 0 25px;
      }
      @include pc_tab {
        margin: 0 0 40px;
      }

      &::after {
        position: absolute;
        bottom: -9px;
        left: 50%;
        display: block;
        width: 37px;
        border-top: 1px solid rgba(255, 255, 255, .8);
        content: "";
        transform: translateX(-50%);
      }
    }
  }
  .num {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;

    @include sp {
      bottom: 30px;
      @media screen and (max-width: 374px) {
        bottom: 5vw;
      }
    }
    @include pc_tab {
      bottom: 40px;
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: block;
    width: 22px;
    height: 42px;
    color: transparent;
    transform: translateY(-50%);
    &.slick-prev {
      left: 25px;
      background: url(#{$img_dir}/common/arw_prev.svg);
      background-size: contain;
    }
    &.slick-next {
      right: 25px;
      background: url(#{$img_dir}/common/arw_next.svg);
      background-size: contain;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -28px;
    left: 50%;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    transform: translateX(-50%);
    li {
      display: block;
      width: 8px;
      height: 8px;
      background: $color_1;
      color: transparent;
      transition: transform .3s;
      + li {
        margin: 0 0 0 7px;
      }
      &.slick-active {
        border: 1px solid $color_1;
        background: $white;
        transform: rotate(45deg);
      }
    }
  }
}

// c_menu
.c_menu {
  position: relative;

  @include pc_tab {
    display: flex;
    overflow-x: hidden;
    width: 100%;
  }
  .item {
    @include sp {
      position: relative;
    }
    @include pc_tab {
      width: 50%;
    }
    & + .item {
      @include sp {
        margin: 20px 0 0;
      }
    }
  }
  .link {
    .ttl {
      position: absolute;
      z-index: 2;
      display: block;
      background: rgba(166, 138, 95, .95);
      color: $white;
      transition: opacity .3s;

      @include sp {
        top: 0;
        left: 20px;
        width: 90px;
        height: 83.7vw;
        .ttl_inner {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: auto;
          height: auto;
          white-space: nowrap;
          transform: translate(-50%, -50%);
        }
      }
      @include pc {
        top: 50%;
        left: calc(25vw - 35px);
        width: 70px;
        height: calc(100% - 100px);
        transform: translateY(-50%);
      }
      @media (min-width: 768px) and (max-width: 1334px) {
        top: 50%;
        left: calc(25vw - 35px);
        width: 70px;
        height: calc(100% - 20px);
        transform: translateY(-50%);
      }
      .note {
        margin: -13px 0 -7px;
      }
      &._more {
        @include sp {
          display: none;
        }
        @include pc_tab {
          background: $color_2;
          opacity: 0;
        }
      }
      .arrow {
        position: relative;
        margin: 9px 0 0;
        &::after {
          content: url(#{$img_dir}/common/icon_arrow_2.svg);
        }
      }
    }
    .txt_wrap {
      @include sp {
        display: none;
      }
      @include pc_tab {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 3;
        overflow: hidden;
        width: 0;
        height: 100%;
        background: $black;
        color: $white;
        transition: width .3s;
      }
      .txt_box {
        position: absolute;
        left: 0;
        z-index: 2;
        padding: 48px 100px;
        height: 100%;
        .txt {
          white-space: normal;
          word-break: break-all;
        }
        .menu_ttl {
          position: relative;
          padding: 0 0 0 50px;
          &::after {
            position: absolute;
            left: 25px;
            display: block;
            width: 1px;
            height: 100%;
            border-left: 1px solid $white;
            content: "";
          }
          .note {
            margin: -13px 0;
          }
        }
        .lead {
          padding: 0 0 0 25px;
        }
      }
    }
    .img_wrap {
      .photo {
        width: 100%;
        height: auto;

        @include sp {
          height: 83.7vw;
        }
        @include pc_tab {
          opacity: 1;
          transition: opacity .3s;
        }
      }
    }
    &:hover {
      .ttl {
        @include pc_tab {
          opacity: 0;
          &._more {
            opacity: 1;
          }
        }
      }
      .txt_wrap {
        @include pc_tab {
          width: 50%;
        }
      }
      .img_wrap {
        .photo {
          @include pc_tab {
            opacity: .4;
          }
        }
      }
    }
  }
  .item:last-of-type {
    .ttl {
      @include sp {
        right: 20px;
        left: auto;
      }
      @include pc_tab {
        right: calc(25vw - 35px);
        left: auto;
      }
    }
    .txt_wrap {
      right: 50%;
      left: auto;
      .txt_box {
        right: 0;
        left: auto;
      }
    }
  }
}

// c_info_list
.c_info_list {
  margin: 0 auto 0;

  @include pc_tab {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    position: relative;
    margin: 0 0 40px;

    @include sp {
      width: 100%;
    }
    @include pc_tab {
      width: calc(100% / 3 - (40px / 1.5));
    }

    & + .item {
      @include pc_tab {
        margin-left: 40px;
      }
      &::before {
        @include sp {
          position: absolute;
          top: -20px;
          display: block;
          width: 100%;
          height: 1px;
          border-top: 1px solid $color_4;
          content: "";
        }
      }
    }
    & + .item:nth-of-type(3n + 1) {
      @include pc_tab {
        margin-left: 0;
      }
    }
  }
  .link {
    position: relative;
    display: block;
    width: 100%;
    color: $white;

    @include pc_tab {
      padding-bottom: 34px;
      transition: opacity .3s;
    }
    .info_header {
      @include sp {
        display: flex;
        align-items: center;
      }
    }
    .img_wrap {
      position: relative;
      overflow: hidden;

      @include sp {
        width: 40.5vw;
        height: 24.3vw;
      }
      @include pc_tab {
        padding-top: 59.7%;
        width: 100%;
      }
      .img {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include pc_tab {
          transition: transform .3s;
        }
      }
    }
    .ttl {
      @include sp {
        padding: 0 0 0 15px;
        width: calc(59.5vw - 40px);
      }
      @include pc_tab {
        margin: 20px 0 0;
      }
    }
    .detail {
      margin: 15px 0 0;
      color: $color_3;
    }
    .info_footer {
      @include sp {
        margin: 15px 0 0;
      }
      @include pc_tab {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &:hover {
      @include pc_tab {
        opacity: .6;
        .img_wrap {
          .img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .category {
    position: relative;

    @include sp {
      margin: 0 16px 0 0;
    }
    @include pc_tab {
      margin: 0 24px 0 0;
    }
    &::after {
      position: absolute;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      border-right: 1px solid $color_3;
      content: "";

      @include sp {
        right: -8px;
      }
      @include pc_tab {
        right: -12px;
      }
    }
  }
  .date {
    color: $color_3;
  }
}

.c_route {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @include pc_tab {
    justify-content: space-between;
    width: 100%;
    &::before {
      position: absolute;
      top: 31px;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 1px;
      border-top: 1px solid $color_7;
      content: "";
    }
  }
  .place {
    position: relative;

    @include sp {
      margin-bottom: 40px;
      width: 30px;
    }
    @include pc_tab {
      margin-bottom: 54px;
      width: 59px;
    }
    .place_inner {
      position: relative;
      display: block;
      background: $color_2;

      @include sp {
        width: 30px;
        height: 90px;
      }
      @include pc_tab {
        width: 59px;
        height: 120px;
      }
      .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        white-space: nowrap;
        transform: translate(-50%, -50%);
      }
    }
    .note {
      position: absolute;
      left: 50%;
      white-space: nowrap;
      word-break: keep-all;
      transform: translateX(-50%);

      @include sp {
        margin-top: 10px;
      }
      @include pc_tab {
        margin-top: 14px;
      }
    }
  }
  .arrow {
    margin-bottom: 11px;

    @include sp {
      margin-left: 3px;
      width: 10px;
      height: 14px;
    }
    @include pc_tab {
      width: 16px;
      height: 23px;
    }
  }
  .time {
    position: relative;
    z-index: 1;

    @include sp {
      padding: 10px 8px 0;
      &::before {
        position: absolute;
        top: 17px;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 1px;
        border-top: 1px solid $color_7;
        content: "";
      }
    }
    @include pc_tab {
      padding: 20px 16px 0;
    }
    .num {
      margin: 5px 0;

      text-combine-upright: all;
    }
  }
}

// c_blog_list
.c_blog_list {
  @include sp {
  }
  @include pc_tab {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    position: relative;

    @include pc_tab {
      margin: 0 0 60px;
      width: calc(50% - 20px);
    }
    .link {
      color: $white;
    }
    .c_ttl_6 {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      margin: 0 0 20px;
      max-height: 26px;
      -webkit-line-clamp: 1;
    }
    .txt_wrap {
      margin: 0 0 20px;
      padding: 20px;
      background: $color_5;
    }
    .txt_inner {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      max-height: 168px;
      -webkit-line-clamp: 4;
    }
    .blog_footer {
      margin: 20px 0 0;
    }
    .category {
      position: relative;

      @include sp {
        margin: 0 16px 0 0;
      }
      @include pc_tab {
        margin: 0 24px 0 0;
      }
      &::after {
        position: absolute;
        top: 0;
        display: block;
        width: 1px;
        height: 100%;
        border-right: 1px solid $color_3;
        content: "";

        @include sp {
          right: -8px;
        }
        @include pc_tab {
          right: -12px;
        }
      }
    }
    .date {
      color: $color_3;
    }
    .btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      &::after {
        position: absolute;
        top: 50%;
        left: -12px;
        z-index: 3;
        content: url(#{$img_dir}/common/icon_arrow_5.svg);
        transform: translateY(-50%);
      }
    }
    & + .item {
      @include sp {
        margin: 40px 0 0;
      }
    }
    & + .item:nth-of-type(even) {
      @include pc_tab {
        margin: 0 0 60px 40px;
      }
    }
  }
}

// c_sheet_detail
.c_sheet_detail {
  .item {
    position: relative;
    z-index: 2;

    @include pc_tab {
      cursor: pointer;
    }
    .u_wm_rl_2 {
      @include sp {
        writing-mode: horizontal-tb;
      }
    }
    .link {
      position: relative;
      width: 100%;
      color: $white;

      @include sp {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        height: 180px;
      }
      @include pc_tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
    .img_wrap {
      @include pc_tab {
        width: 73.2%;
        height: 480px;
      }
      @include sp {
        width: 100%;
        height: 180px;
      }
      .photo {
        width: 100%;
        object-fit: cover;

        @include pc_tab {
          height: 480px;
        }
        @include sp {
          width: 100%;
          height: 180px;
        }
      }
    }
    .txt_bg {
      @include sp {
        display: none;
      }
      @include pc_tab {
        position: absolute;
        top: 50%;
        right: calc(73.2% - 150px);
        z-index: 0;
        padding: 63px 106px;
        width: 431px;
        height: 420px;
        border: 1px solid $color_1;
        background: rgba(0, 0, 0, .81);
        transition: top .3s, right .3s, transform .3s, width .3s,
        height .3s;
        transform: translateY(-50%);
      }
    }
    .txt_wrap {
      position: relative;

      @include sp {
        position: absolute;
        top: 150px;
        z-index: 2;
        margin: 0 20px 0;
        padding: 15px 0 0;
        min-height: 86px;
        width: 100%;
      }
      @include pc_tab {
        position: absolute;
        top: 50%;
        right: calc(73.2% - 150px);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 431px;
        height: 420px;
        transform: translateY(-50%);
      }
      .label {
        @include sp {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          width: calc(100% - 40px);
        }
        .lead {
          @include sp {
            margin-left: 18px;
          }
          @include pc_tab {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid rgba(255, 255, 255, .4);
          }
        }
      }
      .ttl_label {
        position: relative;
        z-index: 2;
        display: block;

        @include pc_tab {
          margin-left: 40px;
          width: 70px;
          height: 294px;
          background: rgba(166, 138, 95, .95);
          color: $white;
          .ttl_inner {
            position: absolute;
            top: 50%;
            left: 50%;
            white-space: nowrap;
            word-break: keep-all;
            transform: translate(-50%, -50%);
          }
        }
      }
      .arrow {
        margin: 9px 0 0;

        &.u_sp {
          @include sp {
            position: absolute;
            bottom: 10px;
            display: flex;
            align-items: center;
          }
        }
        &::after {
          @include sp {
            position: relative;
            display: block;
            margin: 0 0 0 5px;
            content: url(#{$img_dir}/common/icon_arrow_8.svg);
            transition: transform .3s;
            transform: rotate(0);
          }
          @include pc_tab {
            content: url(#{$img_dir}/common/icon_arrow_2.svg);
          }
        }
      }
    }
    .detai_box {
      position: absolute;
      left: 0;
      height: 100%;

      @include sp {
        top: 150px;
        z-index: 1;
        margin: 0 20px;
        padding: 0 20px;
        width: calc(100% - 40px);
        height: 86px;
        border: 1px solid $color_1;
        background: rgba(0, 0, 0, .81);
        transition: height .3s, padding .3s;
        .inner,
        .c_table_1 {
          display: none;
        }
      }
      @include pc_tab {
        top: 0;
        z-index: 3;
        display: flex;
        align-items: flex-start;
        flex-direction: row-reverse;
        justify-content: center;
        padding: 93px 0;
        width: 100%;
        opacity: 0;
        transition: opacity .3s;
      }
      .u_wm_rl_2 {
        @include sp {
          writing-mode: horizontal-tb;
        }
      }
      .inner {
        @include pc_tab {
          height: 100%;
        }
        .txt {
          @include sp {
            margin-bottom: 20px;
          }
        }
      }
      .inner_ttl {
        @include pc_tab {
          margin-left: 25px;
          padding-left: 25px;
          border-left: 1px solid rgba(255, 255, 255, .4);
        }
      }
      .lead {
        @include pc_tab {
          margin-left: 20px;
        }
      }
      .c_table_1 {
        border-top: 1px solid rgba(255, 255, 255, .25);

        @include pc_tab {
          margin: 0 80px;
          width: 381px;
        }
        .row {
          border-bottom: 1px solid rgba(255, 255, 255, .25);
        }
      }
      .btn_wrap {
        position: relative;

        @include sp {
          margin: 20px auto 0;
        }
        .btn {
          position: relative;
          display: block;
          background: $color_2;
          color: $white;

          @include sp {
            padding: 15px;
          }
          @include pc_tab {
            padding: 64px 22px 18px;
          }
          .icon {
            position: absolute;

            @include sp {
              right: 30px;
              width: 32px;
              height: 32px;
            }
            @include pc_tab {
              bottom: 18px;
              left: 13px;
              width: 48px;
              height: 48px;
            }
          }
        }
        .num_2 {
          @include pc_tab {
            position: absolute;
            top: 10px;
            left: 33px;
            letter-spacing: normal;
            transform: rotate(-90deg);
          }
        }
      }
    }
    .num {
      text-combine-upright: all;
    }
    &:nth-of-type(odd) {
      .link {
        @include pc_tab {
          justify-content: flex-end;
        }
      }
    }
    &:nth-of-type(even) {
      .link {
        @include pc_tab {
          justify-content: flex-start;
        }
      }
      .txt_bg {
        @include pc_tab {
          right: calc(26.8% - 283px);
        }
      }
      .txt_wrap {
        @include pc_tab {
          right: calc(26.8% - 283px);
        }
      }
    }
    & + .item {
      @include sp {
        margin: 96px 0 0;
      }
      @include pc_tab {
        margin: 40px 0 0;
      }
    }
    .bg_wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity .3s;
      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.activeitem {
      position: relative;
      z-index: 3;

      @include pc_tab {
        cursor: default;
      }
      .txt_bg {
        @include pc_tab {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          transform: translateY(0);
        }
      }
      .txt_wrap {
        .arrow {
          &.u_sp {
            &:after {
              @include sp {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .detai_box {
        opacity: 1;

        @include sp {
          padding: 90px 20px 15px;
          height: auto;
        }
      }
      .bg_wrap {
        opacity: 1;

        @include sp {
          background: $black;
        }
        .photo {
          @include sp {
            opacity: .6;
          }
        }
      }
    }
  }
}

// c_archivelist
.c_archivelist {
  @include pc_tab {
    display: flex;
    justify-content: space-between;
  }
  .item {
    position: relative;
    background-position: center;
    background-size: cover;

    @include sp {
      padding: 28px 0;
    }
    @include pc_tab {
      padding: 45px 0;
      width: calc((100% - 30px) / 3);
    }
    &._course {
      @include sp {
        background-image: url(#{$img_dir}/common/sp/archivelist_course_bg.png);
      }
      @include pc_tab {
        background-image: url(#{$img_dir}/common/archivelist_course_bg.png);
      }
    }
    &._info {
      @include sp {
        background-image: url(#{$img_dir}/common/sp/archivelist_info_bg.png);
      }
      @include pc_tab {
        background-image: url(#{$img_dir}/common/archivelist_info_bg.png);
      }
    }
    &._access {
      @include sp {
        background-image: url(#{$img_dir}/common/sp/archivelist_access_bg.png);
      }
      @include pc_tab {
        background-image: url(#{$img_dir}/common/archivelist_access_bg.png);
      }
    }
    &._view {
      @include sp {
        background-image: url(#{$img_dir}/common/sp/archivelist_view_bg.png);
      }
      @include pc_tab {
        background-image: url(#{$img_dir}/common/archivelist_view_bg.png);
      }
    }
    &::after {
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      border: 1px solid $color_1;
      content: "";
    }
    .ttl {
      margin: 0 0 20px;
    }
    & + .item {
      @include sp {
        margin-top: 20px;
      }
    }
  }
}

// c_grid_1
.c_grid_1 {
  @include pc_tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item {
    @include pc_tab {
      width: calc(50% - 20px);
    }
    .img_wrap {
      width: 100%;
      .photo {
        width: 100%;
        object-fit: cover;
      }
    }
    .txt_wrap {
      @include sp {
        padding: 30px 0 40px;
      }
      @include pc_tab {
        padding: 30px 0 60px;
      }
    }
    .ttl {
      margin: 0 0 20px;
    }
  }
}

// c_grid_2
.c_grid_2 {
  @include pc_tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .item {
    @include pc_tab {
      width: calc(33.3% - 30px);
    }
    & + .item {
      @include pc_tab {
        margin-left: 45px;
      }
    }
    .img_wrap {
      width: 100%;
      .photo {
        width: 100%;
        object-fit: cover;
      }
    }
    .txt_wrap {
      @include sp {
        padding: 20px 0 40px;
      }
      @include pc_tab {
        padding: 20px 0 0;
      }
    }
    .ttl {
      margin: 0 0 20px;
    }
  }
}

/* =================================
   item
  ================================= */
// c_item_1

// c_item_2

// c_item_3

/* =================================
   slider
  ================================= */
.c_slider {
  .slick-dots {
    display: flex;
    li {
      position: relative;
      display: inline-block;
      padding: 0;
      width: 20px;
      height: 20px;
      &.slick-active {
        button {
          &:before {
            background: #888;
          }
        }
      }
      button {
        display: block;
        padding: 5px;
        width: 20px;
        height: 20px;
        border: 0;
        background: transparent;
        color: transparent;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        &:before {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: #ddd;
          content: "";
          text-align: center;
          -webkit-font-smoothing: antialiased;
          font-size: 30px;
          line-height: 20px;
          opacity: 1;
        }
      }
    }
  }
}

.c_slider_2 {
  .slide {
    position: relative;
    .item {
      height: fit-content;
      .image {
        padding: 0 15px;
        min-height: 562px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include sp {
          padding: 0 7.5px;
          min-height: 170px;
        }
      }
    }
    .prev,
    .next {
      position: absolute;
      bottom: -22px;
      z-index: 1;
      cursor: pointer;

      @include sp {
        bottom: -17px;
      }
      &:before {
        content: url(#{$img_dir}/common/arw_prev.svg);

        @include sp {
          content: url(#{$img_dir}/common/sp/arw_prev.svg);
        }
      }
    }
    .prev {
      left: calc(50% - 92px);

      @include sp {
        left: calc(50% - 68.5px);
      }
    }
    .next {
      left: calc(50% + 70px);
      transform: rotate(180deg);

      @include sp {
        left: calc(50% + 46.5px);
      }
    }
  }
  .counter {
    color: $color_11;
    text-align: center;
    letter-spacing: 1.38px;
    font-size: 23px;
    transform: translate(0, -50%);

    @include sp {
      letter-spacing: .96px;
      font-size: 16px;
    }
    .current {
      transform: translate(-15px, -2px);

      @include sp {
        transform: translate(-12px, -3px);
      }
    }
    .total {
      transform: translate(19px, 6px);

      @include sp {
        transform: translate(11px, 4px);
      }
    }
    &:after {
      position: absolute;
      bottom: 24px;
      left: calc(50% - 43.5px);
      display: block;
      width: 87px;
      height: 1px;
      background: $color_11;
      content: "";
      transform: rotate(-45deg);

      @include sp {
        bottom: 20.75px;
        left: calc(50% - 29.35px);
        width: 58.7px;
      }
    }
  }
}

/* =================================
   pager
  ================================= */
.c_pager_num {
  display: flex;
  align-items: center;
  justify-content: center;
  .page-numbers {
    display: block;
    width: 35px;
    height: 35px;

    @include pc_tab {
      width: 42px;
      height: 42px;
    }
    & + .page-numbers {
      margin: 0 0 0 4px;

      @include pc_tab {
        margin: 0 0 0 5px;
      }
    }
  }
  .page-numbers:not(.prev):not(.next):not(.first):not(.last) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $color_1;
    background: $black;
    color: $white;
    font-size: 12px;
    line-height: 18px;

    @include pc_tab {
      background: $black;
      font-size: 16px;
      line-height: 24px;
      transition: background-color .3s, color .3s;
    }
  }
  .page-numbers:not(.prev):not(.next):not(.first):not(.last).current {
    border: 1px solid $color_1;
    background: $color_1;
    color: $white;
    cursor: auto;
  }
  span.page-numbers {
    background: $color_1 !important;
    color: $white !important;
  }
  .page-numbers:not(.prev):not(.next):not(.first):not(.last):hover {
    @include pc_tab {
      background: $color_1 !important;
      color: $white !important;
    }
  }
  .page-numbers.prev,
  .page-numbers.next,
  .page-numbers.first,
  .page-numbers.last {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $color_3;
  }
  .page-numbers.first {
    position: relative;
    &::before {
      position: absolute;
      content: url(#{$img_dir}/common/icon_arrow_new.svg);
    }
    span {
      display: none;
    }
  }
  .page-numbers.prev {
    position: relative;
    &::before {
      content: url(#{$img_dir}/common/icon_arrow_prev.svg);
    }
    span {
      display: none;
    }
  }
  .page-numbers.last {
    position: relative;
    &::before {
      position: absolute;
      content: url(#{$img_dir}/common/icon_arrow_old.svg);
    }
    span {
      display: none;
    }
  }
  .page-numbers.next {
    position: relative;
    &::before {
      content: url(#{$img_dir}/common/icon_arrow_next.svg);
    }
    span {
      display: none;
    }
  }
}

/* =================================
   other
  ================================= */
// c_note
.c_note {
  position: relative;
  padding: 0 0 0 18px;
  &::before {
    position: absolute;
    left: 0;
    content: "※";
  }
}

// c_link_1
.c_link_1 {
  text-decoration: underline;

  @include pc_tab {
    &:hover {
      text-decoration: none;
    }
  }
  &._external {
    position: relative;
    &::after {
      margin: 0 0 0 6px;
      content: url(#{$img_dir}/common/icon_external.svg);
    }
  }
}

// c_cta_1
.c_cta {
  &._1 {
    position: relative;
    z-index: 0;

    @include sp {
      margin: 119px auto 0;
      padding: 0 0 50px;
    }
    @include pc_tab {
      margin: 250px auto 0;
      padding: 0 0 80px;
    }
    .u_content_size {
      @include sp {
        padding: 50px 20px 0;
      }
      @include pc_tab {
        padding: 50px 0 0;
      }
    }
    .contact_wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      background: rgba($color: #000, $alpha: .5);

      @include sp {
        padding: 50px 20px 40px;
      }
      @include pc_tab {
        padding: 70px 43px;
      }
      .tel_wrap,
      .address_wrap {
        @include pc_tab {
          margin: 0;
        }
      }
      .tel_wrap {
        position: relative;

        @include sp {
          margin: 40px auto 46px;
          width: 100%;
        }
        &::after {
          position: absolute;
          display: block;
          content: "";

          @include sp {
            bottom: -27px;
            left: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid $color_4;
          }
          @include pc_tab {
            top: 0;
            right: -50px;
            width: 1px;
            height: 100%;
            border-right: 1px solid rgba($color: #fff, $alpha: .6);
          }
        }
      }
      .icon_tel {
        @include sp {
          margin: 0 8px 6px 0;
        }
        @include pc_tab {
          margin: 0 10px 6px 0;
        }
      }
      .tel_btn {
        position: relative;
        display: block;

        @include sp {
          margin: 19px 0 0;
          width: 100%;
          height: 70px;
          border-radius: 35px 35px 35px 35px;
          background: $color_1;
          color: $white;
          &::before {
            position: absolute;
            top: 5px;
            left: 5px;
            display: block;
            width: 100%;
            height: 70px;
            border: 1px solid $color_1;
            border-radius: 35px 35px 35px 35px;
            content: "";
          }
          &::after {
            position: absolute;
            top: 50%;
            right: 15px;
            z-index: 3;
            content: url(#{$img_dir}/common/icon_arrow_1.svg);
            transform: translateY(-50%);
          }
          .tel {
            line-height: 70px;
          }
        }
      }
    }
    .block_heading {
      @include sp {
        width: 100%;
      }
      .logo {
        @include sp {
          width: 122px;
          height: auto;
        }
      }
    }
    .note {
      padding: 5px 5px 5px 220px;
      width: 100%;

      @include sp {
        padding: 0;
      }
    }
    .btns {
      width: 100%;
      .c_btn_3 {
        @include sp {
          display: block;
          margin: 25px 0 0;
          width: 100%;
        }
      }
      .c_btn_3 + .c_btn_3 {
        @include sp {
          margin: 25px 0 0;
        }
        @include pc_tab {
          margin: 20px 0 0 15px;
        }
      }
    }
  }
  &._2 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;

    @include sp {
      display: flex;
    }
    @include pc_tab {
      display: none;
    }
    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px 0 11px;
      width: 33.3%;
      color: $white;
    }
    .tel_btn {
      background: #2b2b2b;
    }
    .form_btn_1 {
      background: $color_2;
    }
    .form_btn_2 {
      background: $color_1;
    }
    .icon_tel {
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
    .icon_form {
      margin-right: 6px;
      width: 23px;
      height: 23px;
    }
  }
}
.p_top {
  .c_cta {
    &._1 {
      @include pc_tab {
        margin: 150px auto 0;
      }
    }
  }
}

// c_langbar
.c_langbar {
  @include sp {
    margin: 20px 0;
  }
  @include pc_tab {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
  &._relative {
    position: relative;
  }
  .lang_list {
    display: flex;

    @include sp {
      width: 100%;
    }
    .item {
      border: 1px solid $color_1;
      background: $color_1;
      font-family: $font_3;

      @include sp {
        width: calc(100% / 5);
      }
      .link {
        display: block;
        width: 100%;
        height: 100%;
        color: $white;

        @include sp {
          padding: 8px 0;
        }
        @include pc_tab {
          padding: 10px 20px;
        }
      }
      &.active {
        .link {
          background: $black;
        }
      }
    }
  }
}

// c_cate_1
.c_cate_1 {
  @include sp {
    padding: 0 20px 60px;
  }
  @include pc_tab {
    padding: 0 0 80px;
  }
  .c_catelist {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 17px 0;
    max-width: 768px;
    background: $color_5;
    .item {
      position: relative;

      & + .item {
        @include sp {
          margin: 0 0 0 8px;
          padding: 0 0 0 24px;
        }
        @include pc_tab {
          margin: 0 0 0 24px;
          padding: 0 0 0 40px;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "／";
          opacity: .6;
        }
      }
    }
    .link {
      color: $white;

      @include pc_tab {
        transition: color .3s, border .3s;
        &:hover {
          padding: 0 0 8px;
          border-bottom: 1px solid $color_11;
          color: $color_11;
        }
      }
      &.active {
        padding: 0 0 8px;
        border-bottom: 1px solid $color_11;
        color: $color_11;
      }
    }
  }
}

/* =================================
    tab
  ================================= */
div[class^="c_tab_box"] {
  .btn_area {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .tab_btn {
    padding: 17px 0;
    max-width: 400px;
    width: 100%;
    background: $color_5;
    text-align: center;
    cursor: pointer;
    transition: opacity .2s ease 0s;

    @include pc_tab {
      &:hover {
        opacity: .8;
      }
    }
    & + .tab_btn {
      @include sp {
        margin-left: 15px;
      }
      @include pc_tab {
        margin-left: 30px;
      }
    }
    &.active {
      position: relative;
      border-bottom: 1px solid $color_1;
      color: $color_1;
    }
  }
  .panel_area {
    padding: 110px 0 0;

    @include sp {
      padding: 40px 0 0;
    }
  }
  .tab_panel {
    display: none;
    &.active {
      display: block;
    }
  }
}

/* =================================
 Form
================================= */
.c_input {
  display: block;
  padding: 5px 20px;
  width: 100%;
  height: 50px;
  border: 1px solid $color_9;
  border-radius: 5px;
  background: $color_10;
  color: #fff;
  font-size: 1.6rem;
  cursor: pointer;

  @include pc_tab {
    max-width: 380px;
  }

  &::placeholder {
    color: #838383;
  }
}

.c_input_min {
  display: block;
  padding: 5px 40px 5px 20px;
  max-width: 82px;
  width: 100%;
  height: 36px;
  border: 1px solid $color_9;
  border-radius: 5px;
  background: $color_10;
  color: #fff;
  font-size: 1.6rem;
  cursor: pointer;
  &::placeholder {
    color: #838383;
  }
}

.c_input_textarea {
  display: block;
  padding: 15px;
  width: 100%;
  height: 136px;
  border: 1px solid $color_9;
  border-radius: 5px;
  background: $color_10;
  font-size: 1.6rem;
  font-size: 1.6rem;

  @include pc_tab {
    max-width: 565px;
    height: 106px;
  }
  &::placeholder {
    color: #838383;
  }
}
