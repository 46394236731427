/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

.u_b_ib {
  display: inline-block;
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;

  @include pc_tab {
    margin: 0 auto;
    padding: 0;
    width: $content_width;
  }
}

/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 14px;
  line-height: 26px;

  @include pc_tab {
    font-size: 16px;
    line-height: 30px;
  }
}

.u_txt_2 {
  letter-spacing: 12px;
  font-size: 26px;
  line-height: 40px;

  @include pc_tab {
    letter-spacing: 18px;
    font-size: 36px;
    line-height: 50px;
  }
}

.u_txt_3 {
  letter-spacing: .064rem;
  font-size: 16px;
  line-height: 1;

  @include pc_tab {
    letter-spacing: .072rem;
    font-size: 18px;
  }
}

.u_txt_4 {
  font-size: 16px;
  line-height: 30px;
}

.u_txt_5 {
  letter-spacing: 10px;
  font-size: 20px;
  line-height: 1;

  @include pc_tab {
    letter-spacing: 13px;
    font-size: 26px;
  }
  @media screen and (max-width: 374px) {
    letter-spacing: 2vw;
    font-size: 5.3vw;
  }
}

.u_txt_6 {
  letter-spacing: 9px;
  font-size: 18px;
  line-height: 1;

  @include pc_tab {
    letter-spacing: 8px;
    font-size: 22px;
  }

  @media screen and (max-width: 374px) {
    letter-spacing: 2vw;
    font-size: 4.8vw;
  }
}

.u_txt_7 {
  letter-spacing: 8px;
  font-size: 16px;

  @include pc_tab {
    letter-spacing: 7px;
    font-size: 16px;
    line-height: 30px;
  }
  @media screen and (max-width: 374px) {
    letter-spacing: 2vw;
    font-size: 4.3vw;
  }
}

.u_txt_8 {
  letter-spacing: 13px;
  font-size: 26px;
  line-height: 70px;

  @include sp {
    line-height: 35px;
  }
}

.u_txt_9 {
  letter-spacing: 4px;
  font-size: 16px;
  line-height: 70px;

  @include sp {
    line-height: 35px;
  }
}

.u_txt_10 {
  letter-spacing: 11px;
  font-size: 22px;
  line-height: 70px;

  @include sp {
    line-height: 35px;
  }
}

.u_txt_11 {
  letter-spacing: 5px;
  font-size: 26px;
  line-height: 70px;

  @include sp {
    line-height: 35px;
  }
}

.u_txt_12 {
  letter-spacing: 11px;
  font-size: 22px;
  line-height: 70px;

  @include sp {
    font-size: 26px;
    line-height: 35px;
  }
}

.u_txt_13 {
  letter-spacing: .3rem;
  font-size: 20px;
  line-height: 30px;
}

.u_txt_14 {
  letter-spacing: 13px;
  font-size: 26px;
  line-height: 40px;
}

.u_txt_15 {
  letter-spacing: 11px;
  font-size: 22px;
  line-height: 40px;
}

.u_txt_16 {
  letter-spacing: 11px;
  font-size: 22px;
  line-height: 40px;
}

.u_txt_17 {
  font-size: 14px;
  line-height: 22px;
}

.u_txt_18 {
  font-size: 16px;
  line-height: 27px;

  @include pc_tab {
    font-size: 18px;
    line-height: 30px;
  }
}

.u_txt_19 {
  font-size: 18px;
  line-height: 30px;

  @include pc_tab {
    font-size: 20px;
  }
}

.u_txt_20 {
  font-size: 30px;
  line-height: 50px;

  @include pc_tab {
    font-size: 48px;
    line-height: 60px;
  }
}

.u_txt_21 {
  font-size: 14px;
  line-height: 24px;
}

.u_txt_22 {
  font-size: 16px;
  line-height: 22px;
}

.u_txt_23 {
  letter-spacing: 9px;
  font-size: 26px;
  line-height: 1;

  @include pc_tab {
    letter-spacing: 8px;
    font-size: 30px;
  }
}

.u_txt_24 {
  font-size: 20px;
  line-height: 34px;

  @include pc_tab {
    font-size: 26px;
  }
}

.u_txt_25 {
  font-size: 14px;

  @include pc_tab {
    font-size: 20px;
  }
}

.u_txt_26 {
  font-size: 13px;

  @include pc_tab {
    font-size: 16px;
  }
}

.u_txt_27 {
  font-size: 24px;

  @include pc_tab {
    font-size: 30px;
  }
}

.u_txt_28 {
  font-size: 20px;
  line-height: 30px;
}

.u_txt_29 {
  font-size: 12px;

  @include pc_tab {
    font-size: 16px;
  }
}

.u_txt_30 {
  font-size: 18px;
  line-height: 30px;
}

.u_txt_31 {
  font-size: 22px;

  @include pc_tab {
    font-size: 26px;
  }
}

.u_txt_32 {
  font-size: 26px;
  line-height: 1;

  @include pc_tab {
    letter-spacing: 10px;
    font-size: 36px;
    line-height: 50px;
  }
}

.u_txt_33 {
  font-size: 16px;
  line-height: 30px;

  @include pc_tab {
    letter-spacing: 6px;
    font-size: 25px;
  }
}

.u_txt_34 {
  font-size: 17px;
  line-height: 28px;
}

.u_txt_35 {
  font-size: 30px;
}

.u_txt_36 {
  font-size: 16px;
  line-height: 30px;

  @include pc_tab {
    font-size: 20px;
  }
}

.u_txt_37 {
  font-size: 20px;
  line-height: 30px;

  @include pc_tab {
    font-size: 22px;
  }
}

.u_txt_38 {
  font-size: 18px;
  line-height: 38px;
}

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

/* =================================
 text align
================================= */
.u_ta_l {
  text-align: left;
}

.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}
.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_lr {
  @include sp {
    text-align: left;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_wm_rl {
  display: table-cell;
  vertical-align: middle;

  writing-mode: vertical-rl;
}
.u_wm_rl_2 {
  vertical-align: middle;

  writing-mode: vertical-rl;
}
.u_wm_normal {
  writing-mode: horizontal-tb;
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;

  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}

/* =================================
 font color
================================= */
.u_color_1 {
  color: $white;
}

.u_color_2 {
  color: $color_1;
}

.u_color_3 {
  color: $color_11;
}

.u_color_4 {
  color: $color_12;
}

.u_color_5 {
  color: $color_17;
}

.u_color_6 {
  color: $color_20;
}

.u_color_7 {
  color: $color_3;
}

/* =================================
 background color
================================= */
.u_bg_1 {
  background-color: $black;
}

.u_bg_2 {
  background-color: $color_6;
}

.u_bg_3 {
  background-color: rgba(0, 0, 0, .6);
}

.u_bg_4 {
  background-color: $color_8;
}

.u_bg_5 {
  background-color: $color_23;
}

/* =================================
 margin
================================= */
@for $i from 0 through 20 {
  $margin: $i * 5;
  .u_m#{$margin} {
    margin: 5px * $i !important;
  }
}
@for $i from 0 through 20 {
  $margin: $i * 5;
  .u_mt#{$margin} {
    margin-top: 5px * $i !important;
  }
  .u_mb#{$margin} {
    margin-bottom: 5px * $i !important;
  }
  .u_ml#{$margin} {
    margin-left: 5px * $i !important;
  }
  .u_mr#{$margin} {
    margin-right: 5px * $i !important;
  }
}

@include sp {
  @for $i from 0 through 20 {
    $margin: $i * 5;
    .u_m#{$margin}_sp {
      margin: 5px * $i !important;
    }
  }
  @for $i from 0 through 20 {
    $margin: $i * 5;
    .u_mt#{$margin}_sp {
      margin-top: 5px * $i !important;
    }
    .u_mb#{$margin}_sp {
      margin-bottom: 5px * $i !important;
    }
    .u_ml#{$margin}_sp {
      margin-left: 5px * $i !important;
    }
    .u_mr#{$margin}_sp {
      margin-right: 5px * $i !important;
    }
  }
}

@include pc_tab {
  @for $i from 0 through 20 {
    $margin: $i * 5;
    .u_m#{$margin}_pc_tab {
      margin: 5px * $i !important;
    }
  }
  @for $i from 0 through 20 {
    $margin: $i * 5;
    .u_mt#{$margin}_pc_tab {
      margin-top: 5px * $i !important;
    }
    .u_mb#{$margin}_pc_tab {
      margin-bottom: 5px * $i !important;
    }
    .u_ml#{$margin}_pc_tab {
      margin-left: 5px * $i !important;
    }
    .u_mr#{$margin}_pc_tab {
      margin-right: 5px * $i !important;
    }
  }
}

@include pc {
  @for $i from 0 through 20 {
    $margin: $i * 5;
    .u_m#{$margin}_pc {
      margin: 5px * $i !important;
    }
  }
  @for $i from 0 through 20 {
    $margin: $i * 5;
    .u_mt#{$margin}_pc {
      margin-top: 5px * $i !important;
    }
    .u_mb#{$margin}_pc {
      margin-bottom: 5px * $i !important;
    }
    .u_ml#{$margin}_pc {
      margin-left: 5px * $i !important;
    }
    .u_mr#{$margin}_pc {
      margin-right: 5px * $i !important;
    }
  }
}

/* =================================
padding
================================= */
@for $i from 0 through 20 {
  $padding: $i * 5;
  .u_p#{$padding} {
    padding: 5px * $i !important;
  }
}
@for $i from 0 through 20 {
  $padding: $i * 5;
  .u_pt#{$padding} {
    padding-top: 5px * $i !important;
  }
  .u_pb#{$padding} {
    padding-bottom: 5px * $i !important;
  }
  .u_pl#{$padding} {
    padding-left: 5px * $i !important;
  }
  .u_pr#{$padding} {
    padding-right: 5px * $i !important;
  }
}

@include sp {
  @for $i from 0 through 20 {
    $padding: $i * 5;
    .u_p#{$padding}_sp {
      padding: 5px * $i !important;
    }
  }
  @for $i from 0 through 20 {
    $padding: $i * 5;
    .u_pt#{$padding}_sp {
      padding-top: 5px * $i !important;
    }
    .u_pb#{$padding}_sp {
      padding-bottom: 5px * $i !important;
    }
    .u_pl#{$padding}_sp {
      padding-left: 5px * $i !important;
    }
    .u_pr#{$padding}_sp {
      padding-right: 5px * $i !important;
    }
  }
}

@include pc_tab {
  @for $i from 0 through 20 {
    $padding: $i * 5;
    .u_p#{$padding}_pc_tab {
      padding: 5px * $i !important;
    }
  }
  @for $i from 0 through 20 {
    $padding: $i * 5;
    .u_pt#{$padding}_pc_tab {
      padding-top: 5px * $i !important;
    }
    .u_pb#{$padding}_pc_tab {
      padding-bottom: 5px * $i !important;
    }
    .u_pl#{$padding}_pc_tab {
      padding-left: 5px * $i !important;
    }
    .u_pr#{$padding}_pc_tab {
      padding-right: 5px * $i !important;
    }
  }
}

@include pc {
  @for $i from 0 through 20 {
    $padding: $i * 5;
    .u_p#{$padding}_pc {
      padding: 5px * $i !important;
    }
  }
  @for $i from 0 through 20 {
    $padding: $i * 5;
    .u_pt#{$padding}_pc {
      padding-top: 5px * $i !important;
    }
    .u_pb#{$padding}_pc {
      padding-bottom: 5px * $i !important;
    }
    .u_pl#{$padding}_pc {
      padding-left: 5px * $i !important;
    }
    .u_pr#{$padding}_pc {
      padding-right: 5px * $i !important;
    }
  }
}
